import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 20px;

  .container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;

    @media only screen and (min-width: 430px) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (min-width: 481px) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (min-width: 1024px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media only screen and (min-width: 1280px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 10;
  height: 220px;
`;

export const Title = styled.div`
  font-size: 24px;
  text-align: center;
  margin-bottom: 12px;
  margin-top: 12px;
  font-weight: bold;
`;
