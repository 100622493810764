import React, { useEffect, useState } from 'react';

import api from '../../../services/api';
import { BASE } from '../../../utils/environament';

import * as S from './styles';

const url = `${BASE.API}/storage/`;

export default function Parceiros() {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    async function load() {
      const response = await api.get('files?subtype=partners');

      setFiles(response.data.data);
    }

    load();
  }, []);

  return (
    <S.Container>
      <S.Title>Parceiros</S.Title>

      <div className="container">
        {files.map(file => (
          <S.Card key={`parceiro-${file.id}`}>
            <img src={`${url}${file.file}`} alt="parceiro" className="img" />
          </S.Card>
        ))}
      </div>
    </S.Container>
  );
}
