import React from 'react';

import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import Layout from '../components/Layout'; // eslint-disable-line
import SEO from '../components/seo'; // eslint-disable-line
import Header from '../components/Header';
import Partners from '../components/pages/Parceiros';
import Footer from '../components/Footer';
import banner from '../images/banner.jpg';

import { store, persistor } from '../store';

import { View, Container, Banner } from '../components/pages/styles';

export default function Parceiros() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Layout>
          <SEO title="Parceiros" />
          <Header />
          <View>
            <Banner>
              <img src={banner} alt="adote um cão" />
            </Banner>
            <Container>
              <Partners />
            </Container>
          </View>

          <ToastContainer autoClose={3000} />

          <Footer />
        </Layout>
      </PersistGate>
    </Provider>
  );
}
